import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { ButtonPrimary, Footer } from "@components";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { Return } from "images/Goal";
import { Ilustration, Retirar } from "@images";

const FoundSuccess = () => {
  const classes = useStyles();
  return (
    <div className={` ${classes.container}`}>
      <div className={classes.subContainer}>
        <section>
          <div className={classes.headerLayout}>
            <div className={classes.IconMision}>
              <img src={Retirar} />
            </div>
            <div
              onClick={() => navigate(Route.investments)}
              className={classes.return}
            >
              <img src={Return} />
              Volver
            </div>
          </div>
        </section>
        <section className={classes.containerData}>
          <div className={classes.children}>
            <div className={classes.title}>
              Tu retiro se ha realizado con éxito
            </div>
            <div className={classes.secondary}>
              Verás el depósito en tu cuenta en aproximadamente 5 días hábiles
            </div>
            <ButtonPrimary onClick={() => navigate(Route.investments)} text="Volver a Mis Inversiones" />
            <img className={classes.ilustration} src={Ilustration} />
          </div>
        </section>
      </div>
      <Footer className={`${classes.footer} `} />
    </div>
  );
};
export default FoundSuccess;
const useStyles = makeStyles<Theme>(theme => ({
  footer: {
    [theme.breakpoints.down(1100)]: {
      display: "none",
    },
  },
  subContainer: {
    display: "flex",
    marginBottom: "7rem",
  },
  container: {
    overflowX: "hidden",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    color: theme.palette.text.primary,
  },
  children: {
    marginTop: 80,
    position: "relative",
    height: 600,
    "& button": {
      marginTop: "20rem",
    },
  },
  headerLayout: {
    display: "grid",
    gap: "1rem",
    alignItems: "center",
    paddingLeft: 85,
    paddingTop: 50,
    width: 220,
    justifyContent: "center",
    "& img": {
      width: 140,
      height: 50,
    },
  },
  IconMision: {
    "& img": {
      width: 130,
      height: 130,
    },
  },
  containerData: {
    display: "flex",
    gap: "2rem",
    marginLeft: "3rem",
    width: "100%",
    "& > div": {
      cursor: "pointer",
      width: "100%",
    },
  },
  return: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color:theme.palette.text.secondary,
    "& img": {
      width: 50,
      height: 50,
    },
  },
  ilustration: {
    position: "absolute",
    width: 800,
    top: -68,
    right: 0,
  },
  title: {
    fontSize: 48,
    fontWeight: "bold",
    width: 480,
  },
  secondary: {
    fontSize: 24,
    fontWeight: "normal",
    width: 480,
    marginTop: 44,
  },
}));
