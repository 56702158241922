import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from "@material-ui/core";
import {
  ButtonOutlined,
  ButtonPrimary,
  Footer,
  NumericInput,
} from "@components";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { arrowGoal, Create, Return } from "images/Goal";
import {
  AddIcon,
  Copy,
  Ilustration,
  IlustrationMobile,
  Person,
  Remove,
  Retirar,
  Time,
} from "@images";

const FoundSuccessMobile = () => {
  const classes = useStyles();
  return (
    <div className={`${classes.container}`}>
      <section className={classes.header}>
        <img onClick={() => navigate(Route.investments)} src={arrowGoal} />
        <div className={classes.titleHeader}>Retirar Fondos</div>
        <img src={Retirar} className={classes.addIcon} />
      </section>
      <div className={classes.dataContainer}>
        <div className={classes.title}>Tu retiro se ha realizado con éxito</div>
        <img className={classes.ilustration} src={IlustrationMobile} />
        <span>
          Verás el depósito en tu cuenta en aproximadamente 5 días hábiles
        </span>
        <ButtonPrimary text="Volver a Mis Inversiones" onClick={() => navigate(Route.investments)} />
      </div>
      <Footer />
    </div>
  );
};
export default FoundSuccessMobile;
const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    fontFamily: "Montserrat",
    "& .radio": {
      color: theme.palette.primary.main,
    },
    "& >span": {
      color: theme.palette.primary.main,
    },
  },
  addIcon: {
    width: "120px!important",
    position: "absolute",
    right: 20,
    top: 10,
  },
  header: {
    background:
      "linear-gradient(161deg, rgb(0, 170, 188) 30%, rgb(5, 130, 149) 63%, rgb(10, 86, 105) 88%)",
    paddingTop: 30,
    borderRadius: "0 0 30px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingBottom: 15,
    marginBottom: 30,
    "& >img": {
      width: 30,
      marginLeft: "1rem",
    },
    "& >div": {
      paddingLeft: "3rem",
    },
  },
  titleHeader: {
    color: "white",
    fontSize: 20,
  },
  bottom: {},
  buttontxt: {
    fontWeight: 400,
    width: 280,
    margin: "0 auto",
    textAlign: "center",
    color: theme.palette.text.primary,
    marginTop: "2rem",
  },
  title: {
    fontWeight: "bold",
    fontSize: 24,
    width: 270,
    margin: " 0 auto",
    textAlign: "center",
  },
  ilustration: {
    position: "absolute",
    right: 0,
  },
  dataContainer: {
    "& >span": {
      marginTop: "26rem",
      display: "block",
      color: theme.palette.text.secondary,
      width: 300,
      margin: " 0 auto",
      textAlign: "center",
    },
    "& button": {
      marginTop: "2rem",
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
    },
  },
}));
